import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo/landmark-logo.png";
import ing from "../../assets/img/news/inaugoration1.jpg";
import exam from "../../assets/img/news/landmark100exam.jpg";
import career from "../../assets/img/news/careerorentation2.jpg";
import scholar from "../../assets/img/news/scholarship2023.jpg";
import NewsHealine from "../menhome/news-headline";
import NewsLetter from "../newsletter";

function Main() {
  return (
    <>
      <footer
        className="footer-bg footer-p pt-90"
        style={{
          backgroundColor: "#125875",
          backgroundImage: "url(assets/img/bg/footer-bg.png)",
        }}
      >
        <div className="footer-top pb-70" style={{marginBottom:0}}>
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>About Us</h2>
                  </div>
                  <div className="f-contact">
                    <p>
                      <p>
                        We have been providing higher education in Cameroon
                        since 2005 with all programs accredited by the Cameroon
                        Ministry of Higher Education (MINSUP). LANDMARK is the
                        only university in Cameroon that has graduated students
                        in over 41 countries acrose the globe, which include
                        (Canada, USA, England, India, Nigeria, Brazil, Kenya,
                        China, Sourth Korea, Europe, Gambia, South Africa etc).
                      </p>
                      <p>
                        Our BSC and B-Tech programs are mentored by the
                        University of Buea as we have a Memorandum of
                        Understanding with them. We also have Memorandum of
                        Understanding with partner universities abroad (Nigeria,
                        Canada, Britain, USA) for our Master programs and also
                        we have Memorandum of Understanding with highly
                        accredited professional examination boards abroad like
                        the Abc, ACCA, AMBA.
                      </p>
                    </p>
                  </div>
                  <div className="footer-social mt-10">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/lmucameroonofficial"
                      title="Facebook"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/landmark-metropolitan-university-lmu/mycompany/"
                      title="LinkedIn"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                    <a
                      target="_blank"
                      href="https://twitter.com/officiallmubuea"
                      title="Twitter"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@landmarkmetropolitanuniver7770"
                      title="Youtube"
                    >
                      <i className="fab fa-youtube" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@landmarktechnologies6813"
                      title="Youtube"
                    >
                      <i className="fab fa-youtube" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Our Links</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-lmu">About LMU</Link>
                      </li>
                      <li>
                        <Link to="/lmu-schools">Schools</Link>
                      </li>
                      <li>
                        <Link to="/lmu-short-programs">
                          Certifications Programs
                        </Link>
                      </li>
                      <li>
                        <Link to="/lmu-campuse-a">Campus A</Link>
                      </li>
                      <li>
                        <Link to="/lmu-campuse-b">Campus B</Link>
                      </li>
                      {/* <li>
                        <Link to="/lmu-student-residences">
                          Student Hostles
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/lmu-student-life">Students</Link>
                      </li>
                      <li>
                        <Link to="/lmu-alumni">Alumni</Link>
                      </li>
                      <li>
                        <Link to="/lmu-announcements">Announcemnsts</Link>
                      </li> */}
                      <li>
                        <a
                          href="https://www.facebook.com/lmucameroonofficial"
                          target="_blank"
                        >
                          News
                        </a>
                      </li>
                      <li>
                        <a href="https://landmarkmetropolitanuniversity.com/">
                          e-Services
                        </a>
                      </li>
                      <li>
                        <Link to="/lmu-academic-calenda">
                          Academic Calendar
                        </Link>
                      </li>
                      <li>
                        <Link to="/lmu-picture-galery">LANDMARK Gallery</Link>
                      </li>
                      {/* <li>
                        <Link to="/landmark-construction">
                          Landmark Construction
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/lmu-contact"> Contact Us</Link>
                      </li>
                      {/* <li>
                        <Link to="/faq">Faq</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Latest News</h2>
                  </div>
                  <div className="recent-blog-footer">
                    <ul>
                      {/* <li>
                        <div className="thum">
                          <a
                            href="https://www.facebook.com/lmucameroonofficial/posts/pfbid0Rz6i7qTWkznSqA3XHkHJawViDw3NZnQpeAvubnywPKUp4aP1B2uD3B6oBJjeFu4Pl"
                            target="_blank"
                          >
                            <img src={skill} alt="img" />{" "}
                          </a>
                        </div>
                        <div className="text">
                          {" "}
                          <a
                            href="https://www.facebook.com/lmucameroonofficial/posts/pfbid0Rz6i7qTWkznSqA3XHkHJawViDw3NZnQpeAvubnywPKUp4aP1B2uD3B6oBJjeFu4Pl"
                            target="_blank"
                          >
                            LANDMARK 75 days DevOps Skill aquisation program
                          </a>
                          <span>July 14, 2023</span>
                        </div>
                      </li> */}
                      <li>
                        <div className="thum">
                          <a
                            href="https://www.facebook.com/lmucameroonofficial/posts/pfbid02qkYkQgphCuzbWXmHABUYTkfyXyPDeaYb2JB2tkJd9M4BrFVEnq8TSJtva31cFVKXl"
                            target="_blank"
                          >
                            <img src={career} alt="img" />
                          </a>
                        </div>
                        <div className="text">
                          {" "}
                          <a
                            href="https://www.facebook.com/lmucameroonofficial/posts/pfbid02qkYkQgphCuzbWXmHABUYTkfyXyPDeaYb2JB2tkJd9M4BrFVEnq8TSJtva31cFVKXl"
                            target="_blank"
                          >
                            LANDMARK CAREER ORIENTATION AND CAPACITY BUILDING
                            CONFERENCE
                          </a>
                          <span>August 8th, 2023</span>
                        </div>
                      </li>
                      <li>
                        <div className="thum">
                          <a
                            href="https://www.facebook.com/100063855090613/posts/pfbid02wsicSSDyQbsSL62iR4EPQ8Zs1JNNJx5Us14EErqRBW4cLVoYNoUbERHKWB1rCwwzl/"
                            target="_blank"
                          >
                            <img src={scholar} alt="img" />
                          </a>
                        </div>
                        <div className="text">
                          {" "}
                          <a
                            href="https://www.facebook.com/100063855090613/posts/pfbid02wsicSSDyQbsSL62iR4EPQ8Zs1JNNJx5Us14EErqRBW4cLVoYNoUbERHKWB1rCwwzl/"
                            target="_blank"
                          >
                            LANDMARK President Offers Scholarships Worth over
                            100 Million XAF, To Outstanding and the Less
                            Priviledge Students and Pupils in Cameroon
                          </a>
                          <span>September 9th, 2023</span>
                        </div>
                      </li>
                      <li>
                        <div className="thum">
                          <a
                            href="https://www.facebook.com/100063855090613/posts/pfbid02wsicSSDyQbsSL62iR4EPQ8Zs1JNNJx5Us14EErqRBW4cLVoYNoUbERHKWB1rCwwzl/"
                            target="_blank"
                          >
                            <img src={ing} alt="img" />
                          </a>
                        </div>
                        <div className="text">
                          {" "}
                          <a
                            href="https://www.facebook.com/100063855090613/posts/pfbid02wsicSSDyQbsSL62iR4EPQ8Zs1JNNJx5Us14EErqRBW4cLVoYNoUbERHKWB1rCwwzl/"
                            target="_blank"
                          >
                            THE PRESIDENT OF LANDMARK METROPOLITAN UNIVERSITY
                            INSTALLS TOP MANAGEMENT STAFF OF HIS INSTITUTION
                          </a>
                          <span>October 10th, 2023</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Contact Us</h2>
                  </div>
                  <div className="f-contact">
                    <ul>
                      <li>
                        <i className="icon fal fa-phone" />
                        <span>
                          <Link to="tel:+237672339570">+(237)-672-339-570</Link>
                          <br />
                          <Link to="tel:+237694990622">+(237)-694-990-622</Link>
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-envelope" />
                        <span>
                          <Link to="mailto:info@landmarkmetropolitanuniversity.com">
                            info@landmarkmetropolitanuniversity.com
                          </Link>
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-map-marker-check" />
                        <span>
                          Campus A<br />
                          UB Junction Opposite UNICS Bank,
                          <br />
                          Molyko Buea - Cameroon
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-map-marker-check" />
                        <span>
                          Campus B<br />
                          Malingo Street,
                          <br />
                          Molyko Buea - Cameroon
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{margin:0, padding: 0, textAlign: "center" }}>
          <NewsLetter />
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <Link to="/">
                  <img src={logo} alt="img" style={{ borderRadius: "50%" }} />
                </Link>
              </div>
              <div className="col-lg-4 text-center"></div>
              <div className="col-lg-4 text-right text-xl-right">
                Copyright © LANDMARK Metropolitan University{" "}
                {new Date().getFullYear()}.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Main;
