import React, { useState, useEffect } from "react";
import { getSHA256Hash } from "boring-webcrypto-sha256";

function CookieContainer() {
  const [show, setShow] = useState(false);
  const [addCookie, setAddCookie] = useState("");
  const today = new Date();
  const expireDate = new Date(Number(today) + 604800000);

  const setCookie = async () => {
    document.cookie = `_landmarkVisit=${await getSHA256Hash()};expires=${expireDate.toUTCString()}; path=/`;
  };

  useEffect(() => {
    if (document.cookie) {
      /* const cookieDecoder = document.cookie.toString();
      const cookieSplitter = cookieDecoder.split(';');
      console.log(cookieSplitter);
      for (let i=0; i <= cookieSplitter.length; i++) {
        let slicValue = cookieSplitter[i]
        // console.log(typeof slicValue);
        if (slicValue.indexOf("_landmarkVisit") >= 0 ) {
          setShow(false);
        }
      } */
      setShow(false);
    } else setShow(true);
  }, []);

  return (
    <>
      {show && (
        <div
          className="cookieConsentContainer"
          style={{ opacity: 1, display: "block" }}
        >
          <div className="cookieDesc">
            <p style={{ fontSize: "1.1rem" }}>
              In order to provide you a personalized experience, LANDMARK
              Metropolitan University uses cookies on it website. By continuing
              to use this site, you are agreeing to our cookie policy.
            </p>
          </div>
          <div className="cookieButton">
            <a
              onClick={() => {
                setCookie();
                setShow(false);
              }}
            >
              Accept
            </a>
          </div>
        </div>
      )}
    </>
  );
}

export { CookieContainer };
