import React, { useState } from "react";
import * as yup from "yup";
import "./style.css";
import { newsLetterSubs } from "../../firebase/firBaseUtiles";

export default function NewsLetter() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  //form validation and error handling
  const [error, setError] = useState();

  const validateEmail = yup.object({
    email: yup
      .string()
      .email()
      .required(),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
      try {
        newsLetterSubs({email, name},setEmail(''), setName(''))
    //   await validateEmail.validate(email, { abortEarly: false });
    //   alert("Email submitted");
    } catch (error) {
      const newError = {};

      error.inner.forEach((error) => {
        newError[error.path] = error.message;
      });

      setError(newError);
    }
  };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == "name") {
            setName(value)
        }
        else if (name == 'email') {
            setEmail(value)
        }
  };

  return (
    <div className="card-container">
      <div className="content">
        <div>
          <h1 style={{ color: "white", fontSize: "1.2rem" }}>
            Subscribe to LANDMARK Weekly Newsletter by entering your NAME & your EMAIL below
          </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="sub">
            <input
              type="text"
              placeholder="Enter your name"
              name="name"
              value={name}
              onChange={handleChange}
              className="newletterEmail"
            />
            <input
              type="email"
              placeholder="Enter your email address"
              name="email"
              value={email}
              onChange={handleChange}
              className="newletterEmail"
            />
            <input
              type="submit"
              value="Subscribe"
              className="newsletterSubmit"
            />
          </div>
        </form>
        <div className="check" style={{ marginTop: "1rem" }}>
          <p>
            By submitting your email you agree to receive our weekly newsletter
            containing news, events etc that LANDMARK will Like to share with the public. You may
            unsubscribe from the newsletter anytime by seending us a mail at{" "}
            <b>
              <i>communication@landmark.cm</i>
            </b>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
