import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore/lite";
import { firebaseConfig } from "./fireBaseConfig";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export async function newsLetterSubs(input, email, name) {
  let emailExist = false;
  const newsLetterCols = collection(db, "newsLetter");
  const newsLetterSnapshot = await getDocs(newsLetterCols);
  const newsLetterList = newsLetterSnapshot.docs.map((doc) => {
    if (doc.data().email === input.email) {
      alert(
        "It appears you are already a subscribed member to LANDMARK Newsletter. Thank you for trusting LANDMARK"
      );
      emailExist = true;
      let email1 = email;
      let name1 = name;
    }
  });
  if (emailExist == false) {
    await addDoc(newsLetterCols, {
      email: input.email,
      name: input.name,
    });
    alert("You have been successfully added to LANDMARK Newsletter recepiants");
    const email1 = email;
    const name1 = name;
  }
  return;
}
